.cliente-page {
    padding: 20px;
    max-width: calc(100vw - 50px);
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.cliente-header {
    background-color: #007bff;
    color: white;
    padding: 20px;
    border-radius: 8px 8px 0 0;
}

.error-message {
    color: red;
    margin-top: 10px;
}

.card-info {
    text-align: left;
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-info h2 {
    color: #007bff;
}

.title-timeline {
    color: #007bff;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 8px;
    
}

.timeline-container {
    overflow-x: auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: max-content;
    min-height: 150px;
    
}

.timeline-step {
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 8px 8px;
    background-color: #f0f0f0;
    color: white;
    white-space: nowrap;
    flex-shrink: 0;
    border-radius: 5px;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}


.current-step {
    padding: 20px;
}

