.update-card-modal {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000002a;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
}

.update-card-container-update {
    margin-inline: 5px;
    width: 700px;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 70px);
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding-inline: 10px;
}

.update-card-form-container {
    max-width: calc(100vw - 10px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}

.update-card-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.update-card-input {
    background-color: transparent;
    border: 1px solid #ADADAD;
    width: calc(100% - 80px);
    min-height: 48px;
    color: rgb(87, 87, 87);
    outline: none;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 16px;
    padding-inline: 20px;
    text-align: center;
}

.update-card-input:focus {
    border: 2px solid rgb(247, 0, 173);
    transition: border 1.0s;
}

.update-card-input::placeholder {
    color: #ADADAD;
    text-align: center;
}

.update-card-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.update-card-login-logo {
    width: 100px;
}

.update-card-logo-label {
    color: rgb(87, 87, 87);
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.update-card-logo-label:hover {
    color: dodgerblue;
}

.update-card-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgb(197, 197, 197);
    color: white;
    margin-inline: 10px;
}

.update-card-button:hover {
    cursor: pointer;
    background-position: left bottom;
}

.update-card-error-message {
    min-height: 22px;
    height: 22px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: rgb(255, 0, 85);
    font-size: 13px;
}

.update-card-label-input {
    width: calc(100% - 80px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;
    font-size: 16px;
    color: rgb(87, 87, 87);
}

.update-card-footer {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.update-card-title {
    font-size: 16px;
}

.update-card-close-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #aaaaaa;
    color: white;
    margin-inline: 10px;
}

.update-card-close-button:hover {
    cursor: pointer;
    background-position: left bottom;
}

.update-card-btn-select-column {
    border: none;
    background-color: #aaaaaa;
    color: white;
    border-radius: 5px;
    padding-inline: 5px;
    height: 30px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.update-card-status-container {
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-status-card-container {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 8px;
    font-size: 30px;
}

.icon-status-card-vendido {
    color: dodgerblue;
}

.icon-status-card-perdido {
    color: rgb(255, 20, 98);
}

.select-column {
    background-color: rgb(114, 114, 114);
    color: white;
    height: 25px;
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    padding-inline: 5px;
    font-size: 12px;
}

.select-column:focus {
    outline: none;
    border-color: initial;
    box-shadow: none;
}

.update-card-select-column-container {
    width: calc(100% - 80px);
    
}

.header-update-card-container {
    width: calc(100% - 80px);
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(87, 87, 87);
    font-weight: 900;
    font-size: 20px;
}


.cpf-btn-container {
    display: flex;
    width: calc(100% - 80px);
}


.save-client-button {
    min-width: 120px;
    padding-inline: 5px;
    margin-left: 8px;
    height: 48px;
    border-radius: 5px;
    border: none;
    background-color: yellowgreen;
    color: white;
}


.search-client-button {
    min-width: 120px;
    padding-inline: 5px;
    margin-left: 8px;
    height: 48px;
    border-radius: 5px;
    border: none;
    background-color: rgb(143, 50, 205);
    color: white;    
}



/* Estilo do botão de assinatura */


.sign-contract-container {

    width: 90%;
    display: flex;
    margin-bottom: 10px;
    border: solid 1px red;
    border-radius: 5px;
    padding: 8px;
}
.sign-contract-button {
    min-width: 120px;
    padding-inline: 5px;
    min-height: 48px;
    border-radius: 5px;
    border: none;
    color: white;
    transition: background-color 0.3s ease;
    margin-left: 8px;
}

/* Estilo do botão quando o contrato não foi assinado (False) */
.sign-contract-button.false {
    background-color: red;
}

/* Estilo do botão quando o contrato foi assinado (True) */
.sign-contract-button.true {
    background-color: rgb(139, 212, 21);
}

/* Estilo para o texto com a data e quem assinou */
.signed-info {
    margin-left: 10px;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
}
