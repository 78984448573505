/* style.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
}

.signup-container {
  background-color: #F2F2F2;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form-container {
  position: fixed;
  left: auto;
  top: 20px;
  width: 400px;
  height: 700px; /* Aumentei a altura para acomodar os novos campos */
  max-width: calc(100vw - 10px);
  max-height: calc(100vh - 40px);
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.signup-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-input {
  background-color: transparent;
  border: 1px solid #ADADAD;
  width: calc(100% - 60px);
  min-height: 48px;
  color: #777777;
  outline: none;
  margin-bottom: 20px;
  border-radius: 50px;
  font-size: 16px;
  padding-inline: 20px;
  text-align: center;
}

.signup-input:focus {
  border: 1px solid rgb(247, 0, 173);
  transition: border 1.0s;
}

.signup-input::placeholder {
  color: #757575;
  text-align: center;
}

.signup-button {
  width: calc(100% - 60px);
  border-radius: 50px;
  border: none;
  min-height: 54px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  background: linear-gradient(90deg, rgba(47, 207, 253, 1) 50%, rgba(176, 46, 255, 1) 100%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  margin-bottom: 15px;
}

.signup-button:hover {
  cursor: pointer;
  background-position: left bottom;
}

.signup-button-login {
  border: none;
  background-color: transparent;
  color: #ADADAD;
  margin-bottom: 20px;
}

.signup-button-login:hover {
  color: deepskyblue;
  font-weight: 400;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.signup-logo {
  width: 80px;
}

.logo-label {
  color: rgb(92, 92, 92);
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}

.logo-label:hover {
  color: dodgerblue;
}

.error-message {
  min-height: 22px;
  height: 22px;
  margin-bottom: 5px;
  color: rgb(255, 0, 85);
  font-size: 13px;
}

.checkbox-container {
  width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.checkbox-label {
  font-size: 16px;
  color: #777777;
}
