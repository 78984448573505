.login-container {
    background-color: #fcfcfc;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-container {
    position: fixed;
    left: auto;
    top: 60px;
    width: 400px;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 40px);
    background-color: rgba(255, 255, 255, 0.432);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border-radius: 10px;
    z-index: 10;
}



.login-form-login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.login-input-login {
    background-color: transparent;
    border: 1px solid #ADADAD;
    width: calc(100% - 60px);
    min-height: 48px;
    color: #777777;
    outline: none;
    margin-top: 30px;
    border-radius: 50px;
    font-size: 16px;
    padding-inline: 20px;
    text-align: center;
}

.login-input:focus {
    border: 1px solid rgb(247, 0, 173);
    transition: border 1.0s;
}

.login-input::placeholder {
    color: #757575;
    text-align: center;
}

.login-button-new-account {
    border: none;
    background-color: transparent;
    color: #ADADAD;
    margin-bottom: 20px;
}

.login-button-new-account:hover {
    color:deepskyblue;
    font-weight: 400;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.login-logo {
    width: 80px;
    margin-bottom: 10px;
}

.logo-label-login{
    color: rgb(92, 92, 92);
    margin-top: 10px;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
}

.logo-label:hover{
    color:dodgerblue;
}

.login-button-login {
    width: calc(100% - 60px);
    border-radius: 50px;
    border: none;
    min-height: 54px;
    font-size: 16px;
    color: white;
    font-weight: 400;
    background: linear-gradient(90deg, rgba(47, 207, 253, 1) 50%, rgba(176, 46, 255, 1) 100%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
}

.login-button-login:hover {
    cursor: pointer;
    background-position: left bottom;
}

.error-message-login{
    min-height: 25px;
    height: 25px;
    margin-bottom: 5px;
    color: rgb(255, 0, 85);
    font-size: 13px;
}

.img-login-page {
    z-index: 1;
    width: 280px;
    height: auto; /* Manter a altura automática para preservar a proporção */
    margin-bottom: 15px;
    margin-top: 10px;
}

@media (max-height: 700px) {
    .login-form-container {
        top: 50%;
        transform: translateY(-50%);
    }

    .img-login-page {
        z-index: 1;
        width: 200px;
        height: auto; /* Manter a altura automática para preservar a proporção */
        margin-bottom: 15px;
        margin-top: 10px;
    }
}

@media (max-height: 600px) {

    .img-login-page {
        z-index: 1;
        width: 150px;
        height: auto; /* Manter a altura automática para preservar a proporção */
        margin-bottom: 15px;
        margin-top: 10px;
    }
}