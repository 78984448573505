/* Tela principal */
.tv-screen {
    background-color: #1e1e1e;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.container-row-tv-infos {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.container-infos-dia {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 350px;
    margin-right: 10px;
}

.sale-details {
    background-color: rgba(0, 0, 0, 0.295);
    border-radius: 8px;
    min-height: 100px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 16px;
}
.sales-summary-list-list {
    min-width: 250px;
    background-color: #3e3e3e;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    height: 100%;

}

.sales-summary {
    min-width: 250px;
    background-color: #3e3e3e;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
}

.total-sales {
    font-size: 36px;
    color: #ffd700;
}

.top-sales-list {
    margin-top: 20px;
}


.top-sale-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #444;
    margin-bottom: 10px;
    border-radius: 5px;
}


.top-sale-item-list-entity {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px;
    background-color: #444;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 13px;
}

.top-sale-value {
    color: #ffd700;
    font-weight: bold;
}

.sales-list-container {
    width: 80%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}

.sales-list {

    display: flex;

    gap: 10px;
    overflow-x:hidden;
    overflow: hidden;
}

.sale-card {
    background: #3e3e3e;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 250px;
    min-width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content:space-between;
}

.sale-value {
    color: #ffd700;
    font-size: 18px;

}

.sale-username {
    font-size: 14px;
    font-weight: 700;
}

/* Logo SyncsCRM */
.logo-syncs {
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
}

.logo-syncs img {
    width: 40px;
    height: 40px;
}

.logo-syncs h2 {
    color: #ffffff;
    margin-left: 10px;
}

/* Notificação de novas vendas */
.notification-popup-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-popup {
    background: #444444;
    border-radius: 20px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.8);
    display: flex;
    gap: 20px;
    padding: 20px;
}

.highlighted-sale {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #555555;
    border-radius: 15px;
    padding: 10px;
    animation: show-popup 0.5s ease-out forwards;
}

@keyframes show-popup {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.highlighted-avatar {
    width: 350px;
    border-radius: 15px;
    object-fit: contain; /* Garante que a imagem se ajuste ao contêiner sem ser cortada */
    
}

.highlighted-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    margin-top: 20px;
}

.highlighted-value {
    font-size: 36px;
    color: #ffd700;
    margin-top: 10px;
}

.highlighted-bell-icon {
    font-size: 100px;
    color: #ffd700;
    margin-top: 30px;
    animation: bell-swing 1s ease-in-out infinite;
}

@keyframes bell-swing {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.clock {
    background-color: #444;
    padding: 12px;
    border-radius: 8px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #ffffff;
    font-size: 24px;
  }
  

  .progress-bar-container {
    width: 100%;
    background-color: #444;
    border-radius: 5px;
    height: 20px;
    margin-top: 10px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #ffd700;
    border-radius: 5px;
  }
  .sales-chart {
    margin-top: 20px;
  }
  
  .sales-chart-card {
    margin-top: 20px;
    background-color: #00000048;
    padding: 10px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }


  







.last-sale {

    margin-top: 10px;
}
  .last-sale-item {
    display: flex;
    align-items: center;
    background-color: #555;
    padding: 10px;
    border-radius: 5px;
  }
  
  .last-sale-avatar {
    width: 150px;
    height: 150px;
    border-radius: 10px; /* Mantém a imagem circular */
    margin-right: 10px;
    object-fit: contain; /* Garante que a imagem se ajuste ao contêiner sem ser cortada */
    background-color: #fff; /* Adiciona um fundo branco para destacar o logo */
    padding: 10px; /* Dá um pouco de espaço entre a imagem e a borda do círculo */
}

  
  .last-sale-info {
    display: flex;
    flex-direction: column;
  }
  
  .last-sale-value {
    color: #ffd700;
    font-weight: bold;
  }



  .container-logo-entity-card {
    width: 230px;
    height: 230px;
    border-radius: 18px; /* Mantém a imagem circular */
    background-color: #ffffff; /* Adiciona um fundo branco para destacar o logo */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .sale-avatar{
    width: 100%;

    border-radius: 10px; /* Mantém a imagem circular */
    object-fit: contain; /* Garante que a imagem se ajuste ao contêiner sem ser cortada */

  }
  