

.frame-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Garante que o padding seja considerado no tamanho total */
}



.perfil-inferior {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  background-color: red;
}
