* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Evita que as bordas adicionem ao tamanho do elemento */
}

body {
  background-color:white; 
}

