/* style.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
}

.landing-container {
  color: #ffffff;
  text-align: center;
  background: #f8f9fa;
}

.header-container-landing-page {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(168, 86, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);
  width: 100vw;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
}

.header-logo-center-landing-page {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.header-logo-center-logo-landing-page {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.header-logo-center-label-landing-page {
  font-weight: 900;
  font-size: 18px;
}

.header-menu-right-landing-page {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
}

.button {
  min-width: 100px;
  display: inline-block;
  padding: 8px 5px;
  margin-left: 10px;
  background-color: #8400ff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
}

.button-signup {
  min-width: 110px;
}

.button:hover {
  background-color: #0056b3;
}

.landing-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
}

.intro-section {
  padding: 50px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  color: #5e5e5e;
}

.intro-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.intro-section p {
  font-size: 1.2rem;
}

.video-section {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.slider-section {
  position: relative;
  max-width: 1200px;
  margin: 40px auto;
  overflow: hidden;
  border-radius: 10px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 650px; /* Altura fixa para o carrossel */
}

.carousel-image {
  min-width: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  height: 600px; /* Altura fixa para as imagens */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image.active {
  opacity: 1;
}

.carousel img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.features {
  margin-bottom: 40px;
}

.features h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #5f5f5f;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  background-color: #499cfc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 12px;
  width: 250px;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px);
}

.card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #ffffff;
}

.benefit-card:nth-child(1) {
  background-color: #8746ff;
  color: white;
}

.benefit-card:nth-child(2) {
  background-color: #8746ff;
  color: white;
}

.benefit-card:nth-child(3) {
  background-color: #8746ff;
  color: white;
}

.benefit-card:nth-child(4) {
  background-color: #8746ff;
  color: white;
}

.benefit-card:nth-child(5) {
  background-color: #8746ff;
  color: white;
}

.benefits {
  margin-bottom: 40px;
}

.benefits h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #444;
}

.cta-section {
  padding: 50px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  color: #646464;
}

.cta-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2rem;
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #0056b3;
}

.landing-footer {
  background-color: #5e5e5e;
  padding: 10px 0;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
