.modulo-esquadrias-modal {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000002a;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modulo-esquadrias-container {
    position: fixed;
    left: auto;
    top: 40px;
    width: 800px;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 70px);
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    border-radius: 10px;
    margin-inline: 5px;
}

.modulo-esquadrias-form-container {
    width: 100%;
    max-width: calc(100vw 0px);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    overflow-y: auto;
}

.modulo-esquadrias-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}



.modulo-esquadrias-input-text-area {
    background-color: transparent;
    border: 1px solid #ADADAD;
    width: calc(100% - 20px);
    min-height: 48px;
    color: #2c2c2c;
    outline: none;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
}



.modulo-esquadrias-input {
    background-color: transparent;
    border: 1px solid #ADADAD;
    width: calc(100% - 20px);
    min-height: 48px;
    color: #464646;
    outline: none;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 16px;
    padding-inline: 20px;
    text-align: center;
}

.modulo-esquadrias-input:focus {
    border: 2px solid rgb(247, 0, 173);
    transition: border 1.0s;
}

.modulo-esquadrias-input::placeholder {
    color: #ADADAD;
    text-align: center;
}

.module-esquadrias-row-container {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
}

.module-esquadrias-column {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.module-esquadrias-column-faktory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid red 1px;
    max-height: 150px;
    gap: 10px;
    padding: 5px;
    border-radius: 8px;


}

.module-esquadrias-column-faktory-button {
    height: 35px;
    min-height: 35px;
    background-color: rgb(255, 20, 98);
    border: none;
    border-radius: 5px;
    padding: 2px;
    color: white;
}

.update-card-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.update-card-login-logo {
    width: 100px;
}

.update-card-logo-label{
    color: rgb(92, 92, 92);
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.update-card-logo-label:hover{
    color:dodgerblue;
}

.update-module-esquadrias-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgb(61, 139, 255);
    color: white;
    margin-inline: 10px;
}

.update-module-esquadrias-button:hover {
    background-color: rgb(255, 62, 62);
    cursor: pointer;
    background-position: left bottom;
}

.update-card-error-message{
    min-height: 22px;
    height: 22px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: rgb(255, 0, 85);
    font-size: 13px;
}

.modulo-esquadrias-label-input {
    width: calc(100% - 25px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;
    font-size: 16px;
    color: #252525;
}

.update-card-footer {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.update-card-title {
    font-size: 16px;
}

.update-card-close-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #aaaaaa;
    color: white;
    margin-inline: 10px;
}

.update-card-close-button:hover {
    cursor: pointer;
    background-position: left bottom;
}

.update-card-btn-select-column {
    border: none;
    background-color: #aaaaaa;
    color: white;
    border-radius: 5px;
    padding-inline: 5px;
    height: 30px;
    margin-right: 5px;
}

.update-card-status-container {
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-status-card-container {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 8px;
    font-size: 30px;
}

.icon-status-card-vendido {
    color:dodgerblue;
}

.icon-status-card-perdido {
    color: rgb(255, 20, 98);
}



.faktory-container-prazo-entrega {
    border: solid 1px red;
    border-radius: 5px;
    display: flex;
    width: 100px;
    height: 48px;
}


