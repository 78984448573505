.modal-teste {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.295);
    z-index: 10000;
}

.container-teste {
    position: fixed;
    top: 45px;
    width: 700px;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    background: white;
    border-radius: 10px;
    z-index: 10000;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
}

.text-field-btns-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.text-field-btn {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    width: 60px;
    height: 20px;
    min-width: 20px;
    max-height: 20px;
    background-color: rgb(77, 77, 77);
    border-radius: 4px;
    color: white;
    margin-top: 5px;
    font-size: 12px;
}

.text-field-btn:hover {
    background-color: red;
}


.select-card-container {
    width: 600px;
    margin-top: 10px;
    position: relative;
    margin-bottom: 20px;
}

.select-card-label {
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    padding-inline: 4px;
    color: #A1A2A2;
    position: absolute;
    top: -6px;
    left: 10px;
}

.select-card-input {
    width: 100%;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    height: 45px;
    border-radius: 5px;
    border: solid 1px #A1A2A2;
    outline: none;
    color: #505050;
    text-align: center;
}




.select-component-container {
    width: 600px;
    margin-top: 10px;
    position: relative;
    margin-bottom: 20px;
}

.select-component-label {
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    padding-inline: 4px;
    color: #A1A2A2;
    position: absolute;
    top: -6px;
    left: 10px;
}

.select-component-input {
    width: 100%;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    height: 45px;
    border-radius: 5px;
    border: solid 1px #A1A2A2;
    outline: none;
    color: #505050;
    text-align: center;
}

.container-components {
    border: solid 1px rgb(253, 253, 253);
    padding-inline: 5px;
    padding-top: 15px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin-bottom: 8px;
}







/* ----------- add new component ---------- */

.add-new-component-container {
    width: 800px;
    height: calc(100vh - 100px);
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 45px;
    z-index: 100;
    border-radius: 10px;
    color: #505050;
    padding: 5px;
}

.select-item-new-component {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.select-component-item-field {
    min-width: 140px;
    min-height: 140px;
    margin: 5px; /* Adicione margem para espaçamento */
    border-radius: 8px;
    display: flex;
    color: #505050;
    z-index: 101;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.icone-select-component-field {
    font-size: 55px;
    margin-top: 10px;
}

.label-select-component-field {
    font-size: 18px;
}










.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .confirmation-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .confirmation-modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .deactivate-button {
    background-color: #f0ad4e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  







  .module-delete-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #505050;
  }
  
  .module-delete-confirmation-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .module-delete-confirmation-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .module-delete-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .module-cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }


  .btn-add-new-field-module-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .btn-add-new-field-module {
    width: 45px;
    height: 45px;
    background-color: #61c5ff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    font-size: 18px;
  }
  

  .reactivate-field-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .reactivate-field-label{

    color:#505050;
    margin-bottom: 20px;
  }

  .reactivate-field-card {
    min-width: 140px;
    min-height: 140px;
    margin: 5px; /* Adicione margem para espaçamento */
    border-radius: 8px;
    display: flex;
    color: #505050;
    z-index: 101;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
  }
  
  