.avatar-modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.575);
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
    
}

.avatar-container {
    position: fixed;
    top: 40px;
    max-width: calc(710px);
    height: calc(100vh - 100px);
    display: flex;
    justify-content:space-between;
    align-items: flex-start;
    background-color: white;
    box-shadow: rgba(31, 31, 31, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.24) 0px 3px 7px -3px;
    z-index: 1000;
    border-radius: 15px;
    padding: 10px;
}

.avatar-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    z-index: 1000;
    border-radius: 15px;
    overflow-y: auto;
    padding: 10px;
}

.btn-close-modal-avatar{
    position: fixed;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(253, 91, 91);
    font-size: 18px;

}

.btn-close-modal-avatar:hover{
    background-color: rgb(255, 41, 41);

}


.avatar-item {
    border-radius: 10px;
    margin: 8px;
    width: 200px;
    height: 200px;
}



