.meus-testes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}



.resposta-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.resposta-container h3 {
  margin: 0 0 10px 0;
}

.resposta-container p {
  margin: 0;
  white-space: pre-wrap;
}
