.bem-vindo-modal {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.712);
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out;
}

.column-boas-vindas-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bem-vindo-container {
    width: 98%;
    max-width: 600px;
    max-height: 98vh;
    background-color: #ffffffd3;
    z-index: 1011;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribui espaço igualmente entre os elementos */
}

.title-bem-vindo-fixed {
    width: 100%;
    color: #666666;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.bem-vindo-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px;
    text-align: center;
}

.maintenance-message{
    width: 100%;
    color: #666666;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 10px;
}

.welcome-icon {
    width: 100%;
    height: auto;
    animation: bounceIn 0.5s ease-in-out;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    object-fit: cover; /* Garante que a imagem ocupe toda a largura */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.btn-close-bem-vindo {
    position: fixed;
    right: 40px;
    top: 20px;
    width: 50px;
    height: 50px;
    background-color: #ff0d4a;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 22px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    z-index: 500000;
    box-shadow: rgba(248, 104, 104, 0.712) 0px 5px 15px;
    font-weight: 900;
    padding-bottom: 5px;
    
    /* Adiciona a animação */
    animation: pulse 1.5s infinite ease-in-out;
}


.img-container-bem-vindo-botton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-close-bem-vindo:hover {
    background-color: #ff3131;
    transform: scale(1.1);
}

/* Animações de entrada */
.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.slide-in {
    animation: slideIn 0.4s ease-in-out;
}

/* Animações de saída */
.fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
}

.slide-out {
    animation: slideOut 0.4s ease-in-out forwards;
}

.maintenance {
    background-color: #00000094; /* Cor de fundo para o modo de manutenção */
    color: #ff3131; /* Cor do texto em modo de manutenção */
}

.maintenance-container {
    background-color: #414141c4; /* Cor de fundo para o contêiner em modo de manutenção */
}

.maintenance-text {
    color: #fc6060; /* Cor do texto para a mensagem de manutenção */
}

.maintenance .btn-close-bem-vindo {
    background-color: #ff3131; /* Cor do botão fechar em modo de manutenção */
    color: #ffffff;
}

.maintenance .btn-close-bem-vindo:hover {
    background-color: #ff6666; /* Cor do botão fechar ao passar o mouse em modo de manutenção */
}

