.loading-spinner-loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .footer-loading-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .btn-close-loading-component {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
  
  .label-loading {
    margin-top: 20px;
    color: white;
    font-size: 18px;
    text-align: center;
  }
  