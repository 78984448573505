.create-user-container {
    position: fixed;
    left: auto;
    top: 50px;
    width: 700px;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 60px);
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    border-radius: 10px;
    padding-inline: 10px;
}

.create-user-form-container {
    max-width: calc(100vw - 10px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    overflow-y: auto;
}

.create-user-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.create-user-input {
    background-color: transparent;
    border: 1px solid #ADADAD;
    width: calc(100% - 80px);
    min-height: 48px;
    color: #ADADAD;
    outline: none;
    margin-bottom: 30px;
    border-radius: 5px;
    font-size: 16px;
    padding-inline: 20px;
    text-align: center;
}

.create-user-input:focus {
    border: 2px solid rgb(247, 0, 173);
    transition: border 1.0s;
}

.create-user-input::placeholder {
    color: #ADADAD;
    text-align: center;
}

.create-user-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
}

.create-user-login-logo {
    width: 100px;
}

.create-user-logo-label{
    color: rgb(92, 92, 92);
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.create-user-logo-label:hover{
    color:dodgerblue;
}

.create-user-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: dodgerblue;
    color: white;
    margin-inline: 10px;
}

.create-user-button:hover {
    cursor: pointer;
    background-position: left bottom;
}

.create-user-error-message{
    min-height: 22px;
    height: 22px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: rgb(255, 0, 85);
    font-size: 13px;
}

.create-user-label-input {
    width: calc(100% - 80px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 30px;
    font-size: 16px;
}

.create-user-footer {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.create-user-title {
    font-size: 16px;
}

.create-user-close-button {
    width: 100%;
    min-height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #aaaaaa;
    color: white;
    margin-inline: 10px;
}

.create-user-close-button:hover {
    cursor: pointer;
    background-position: left bottom;
}

.edit-avatar-user-icon {
    font-size: 13px;
    margin-bottom: 25px;
    margin-top: 10px;
    width: 100px;
    height: 28px;
    background-color: deepskyblue;
    color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.edit-avatar-user-icon:hover {
    background-color: deeppink;
}


