
.tools-parametros-container {
    width: 100vw;
    min-height: 30px;
    display: flex;
    align-items: center;
}

.btn-select-edit-parameter {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    min-height: 23px;
    background-color:dodgerblue;
    color: white;
    margin-left: 10px;
}

.btn-select-edit-parameter:hover {
    background-color:rgb(106, 181, 255);
}

.parameter-modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.548);
    z-index: 2000;
}



.parameter-container-html {
    width: 98vw;
    max-width: calc(100vw - 10px);
    height: calc(100vh - 50px);
    border-radius: 8px;
    background-color: white;
    position: fixed;
    top: 20px;
    display: flex;
    flex-direction: column;
    padding-inline: 8px; 
}

.parameter-container {
    width: 700px;
    max-width: calc(100vw - 10px);
    height: calc(100vh - 50px);
    border-radius: 8px;
    background-color: white;
    position: fixed;
    top: 20px;
    display: flex;
    flex-direction: column;
    padding-inline: 8px;
}

.parameter-header {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 0px solid rgb(151, 151, 151);
    justify-content: center;
}

.parameter-title {
    font-weight: 900;
    font-size: 18px;
    color: rgb(54, 54, 54);
}

.parameter-body {
    width: 100%;
    min-height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    overflow-y: auto;
    flex-direction: column;
    padding: 10px;
}

.item-list-parameter {
    width: calc(100% - 10px);
    min-height: 50px;
    margin-top: 5px;
    border-radius: 8px;
    box-shadow: rgba(31, 31, 31, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.24) 0px 3px 7px -3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label-item-parameter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-item-parameter-description {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 20px;
}

.btns-item-list {
    display: flex;
}

.btn-remove-item-list {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    min-height: 30px;
    background-color:red;   
    color: white;
    margin-right: 10px;
}

.btn-remove-item-list:hover {
    background-color:rgb(255, 67, 67);
}

.btn-edite-item-list {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    min-height: 30px;
    background-color:dodgerblue;
    color: white;
    margin-right: 10px;
}

.btn-edite-item-list:hover {
    background-color:rgb(106, 181, 255);
}

.parameter-footer {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    border-top: 0px solid rgb(151, 151, 151);
    justify-content: flex-end;
}

.btn-add-parameter {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    min-height: 35px;
    background-color:dodgerblue;
    color: white;
    margin-left: 10px;
}

.btn-add-parameter:hover {
    background-color:rgb(106, 181, 255);
}

.btn-close-parameter {
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    min-height: 35px;
    background-color:rgb(99, 99, 99);
    color: white;
    margin-left: 10px;
}

.btn-close-parameter:hover {
    background-color:rgb(122, 122, 122);
}

























.users-page-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.users-body-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    height: calc(100vh - 40px);
}

.admin-menu-button {
    background-color:deeppink;
    border-radius: 5px;
    border: none;
    height: 30px;
    padding-inline: 8px;
    color: white;
}

.admin-menu-button:hover {
    background-color:dodgerblue;
}

.admin-floating-button {
    position: fixed;
    right: 10px;
    bottom: 100px;
    width: 50px;
    height: 50px;
    background-color: dodgerblue;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 30px;
}

.admin-floating-button:hover {
    background-color:deeppink;
}












.parameter-modal-etiqueta {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.171);
}


.title-select-color-etiqueta-selector {
    margin-top: 10px;
}

.input-select-color-etiqueta-selector {
    width: 300px;
    min-height: 40px;
    border-radius: 5px;
    margin-top: 2px;
    padding: 3px;
}


.color-selector {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .color-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .color-option {
    width: 50px;
    height: 50px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .color-option:hover {
    border: 2px solid #000;
  }
  
  .btn-save-color {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 5px;
    min-height: 30px;
    background-color: dodgerblue;
    color: white;
    margin-top: 10px;
  }
  
  .btn-save-color:hover {
    background-color: rgb(106, 181, 255);
  }


  .color-option.selected {
    border: 2px solid #000;
  }
  

  .btn-associar-coluna {
    height: 40px;
    background-color: yellowgreen;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 8px;
  }

  .btn-associar-coluna:hover {

    background-color: rgb(59, 167, 255);

  }


  .checkbox-cliente {
    margin-top: 25px;
    margin-right: 5px;
    margin-bottom: 25px;
  }


  .btn-desassociar-coluna {
    height: 40px;
    background-color: rgb(255, 59, 85);
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 8px;
  }


  
  .btn-desassociar-coluna:hover {

    background-color: rgb(59, 167, 255);

  }

  .title-edite-column-input {
    margin-top: 10px;
  }




  









  












.engajamento-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

/* Wrapper da tabela para gerenciar a rolagem */
.table-angajamento-users-wrapper {
    width: 90%; /* Largura da tabela */
    max-width: 1200px; /* Largura máxima */
    height: calc(100vh - 170px); /* Altura fixa calculada */
    overflow-y: auto; /* Rolagem vertical */
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #ddd;
}

/* Estilo da tabela */
.table-angajamento-users {
    width: 100%; /* A tabela ocupa todo o wrapper */
    border-collapse: collapse;
    background-color: #ffffff;
}

/* Cabeçalho da tabela */
.table-angajamento-users thead {
    background-color: #4caf50; /* Fundo verde */
    color: #ffffff; /* Texto branco */
    font-weight: bold;
}

.table-angajamento-users thead th {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    position: sticky; /* Cabeçalho fixo */
    top: 0;
    z-index: 2;
}

/* Corpo da tabela */
.table-angajamento-users tbody tr {
    border-bottom: 1px solid #ddd;
}

.table-angajamento-users tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Fundo alternado */
}

.table-angajamento-users tbody tr:hover {
    background-color: #e0e0e0; /* Fundo ao passar o mouse */
}

.table-angajamento-users tbody td {
    padding: 10px;
    font-size: 14px;
    color: #333333;
    text-align: left;
}





/* Botão de buscar */
.engajamento-container button {
    background-color: #4caf50; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.engajamento-container button:hover {
    background-color: #45a049; /* Tom mais escuro ao passar o mouse */
}

/* Estilo para inputs */
.engajamento-container input[type="date"],
.engajamento-container input[type="text"] {
    width: 250px;
    height: 35px;
    padding: 5px 10px;
    margin: 10px 5px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 14px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.engajamento-container input[type="date"]:focus,
.engajamento-container input[type="text"]:focus {
    border-color: #4caf50;
    outline: none;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

