

.create-column-container {
    background-color: white;
    width: 700px;
    height: 400px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: fixed;
    z-index: 1;
    margin-top: 10px;
}

.create-column-form {
    display: flex;
    flex-direction: column;
}

.create-column-input {
    margin-top: 10px;
    margin-bottom: 10px;
}