/* CSS Ajustado para o Calendário */

/* Container principal do modal */
.calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.329); /* Transparência do fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Adiciona espaçamento para ajuste */
}

/* Container do conteúdo do modal */
.calendar-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 95%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Sombra mais suave */
  display: flex;
  flex-direction: column;
}

/* Botão para fechar o modal */
.close-button-calendario {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  width: 70px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 14px;
  transition: background-color 0.3s ease-in-out;
}

.close-button-calendario:hover {
  background-color: #333333; /* Efeito de hover */
}



.task-details-modal {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: #00000042;

  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


/* Container de detalhes da tarefa */
.task-details-container {

  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;

  animation: fadeIn 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.task-details-container h3 {
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 8px;
}




.calendario-style {
  width: calc(100vw);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 38px;
}




/* Calendário */
.rbc-calendar {
  background: #f9f9f9; /* Fundo mais claro */
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); /* Sombra mais suave */
  color: #333; /* Cor padrão para textos */
  font-family: 'Arial', sans-serif;
}

/* Células e cabeçalhos do calendário */
.rbc-header {
  background: #1e88e5; /* Azul mais escuro */
  color: white;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border: none;
}

.rbc-month-view, .rbc-agenda-view {
  color: #444; /* Ajusta a cor dos textos */
}

/* Estilo do dia atual */
.rbc-today {
  background-color: #e1f5fe; /* Azul claro */
  border: 1px solid #0288d1; /* Borda azul */
}

/* Estilo dos eventos */
.rbc-event {
  background-color: #f6296d; /* Azul claro */
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra no evento */
  transition: background-color 0.3s ease;
}

/* Efeito de hover nos eventos */
.rbc-event:hover {
  background-color: #0288d1; /* Efeito de hover com azul mais escuro */
  cursor: pointer;
}

/* Celulas vazias do calendário */
.rbc-month-row {
  background: #f1f1f1;
}

.rbc-month-row .rbc-day-bg {
  border: 1px solid #ddd; /* Borda suave */
}

.rbc-off-range-bg {
  background-color: #f0f0f0; /* Fundo para dias fora do mês */
}

/* Cabeçalho da agenda */
.rbc-agenda-header {
  background: #1976d2; /* Azul mais escuro */
  color: white;
  border: none;
  text-transform: uppercase; /* Textos em maiúsculas */
}

/* Responsividade */
@media (max-width: 768px) {
  .rbc-calendar {
    width: 98vw;
  }
}



.close-button-task-details {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  width: 70px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px; /* Adiciona espaçamento inferior */
  align-self: flex-end; /* Alinha o botão à direita */
  transition: background-color 0.3s ease-in-out;
}

.close-button-task-details:hover {
  background-color: #d9363e; /* Efeito de hover */
}

