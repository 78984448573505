.ordens-producao-container-ordens {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.App-header {
    width: 600px;
    max-width: 98%;
    background-color: #282c34;
    padding: 20px;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.title-ordens {
    font-size: 20px;
    margin-bottom: 30px;
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 8px;
}

.label-input-ordens {
    margin-bottom: 3px;
}

.input-ordens-producao[type="text"] {
    min-height: 40px;
    max-height: 40px;
    font-size: 1em;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-inline: 10px;
    margin-right: 8px;
}

.btns-ordens-producao-container {
    display: flex;  
    min-height: 61px;
    align-items: flex-end;
}

.ordens-producao-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.btn-ordens-producao {
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    min-height: 40px;
    max-height: 40px;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.btn-ordens-producao-qrcode {
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    min-height: 40px;
    max-height: 40px;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons-ordens-producao {
    font-size: 40px;
}

.item-list-ordens {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color:rgb(255, 20, 71);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
}


ul {
    list-style-type: none;
}



.error-message {
    color: #ff0000;
    margin-top: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.scanner-container {
    position: fixed;
    top: 100px;
    width: 700px;
    max-width: 98%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.cam-barcode {
    width: 100%;  /* Usar 100% da largura disponível */
    aspect-ratio: 4 / 1;  /* Define a proporção de 4:1 (largura:altura) */
    max-width: 600px;  /* Limite máximo de largura */
    object-fit: cover;  /* Faz o vídeo preencher a área disponível */
    background-color: #000;  /* Fundo preto para melhorar o contraste */
}



.footer-cam {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    padding-inline: 10px;
    margin-left: 10px;
}

.cancel-button:hover {
    background-color: darkred;
}

.downloading-message {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    color: black;
  }
  