.modal-venda-perdida-container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.venda-perdida-container {
  background-color: white;
  width: 600px;
  max-width: 97%;
  max-height: calc(100vh - 130px);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 55px;
}

.venda-perdida-header {
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  display: flex;
}

.title-venda-perdida {
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
}

.venda-perdida-header .btn-close-container {
  min-height: 70px;
  max-height: 70px;
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
  padding: 5px;
}

.btn-close-container .btn-close {
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
}

.venda-perdida-header .btn-close:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.venda-perdida-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.venda-perdida-body .title {
  width: 100%;
  min-height: 22px;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
}

.venda-perdida-body .input-venda-perdida {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.venda-perdida-body .input-venda-perdida:focus {
  border: 2px solid dodgerblue;
}


.venda-perdida-body .btn-motivos {
  padding-inline: 8px;
  height: 26px;
  margin-bottom: 3px;
  border-radius: 5px;
  border: none;
}

.venda-perdida-body .btn-motivos:hover {
  background-color: deeppink;
  color: white;
}


.venda-perdida-body .btn-motivos:focus {
  background-color: deeppink;
  color: white;
}


.venda-perdida-footer {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding-left: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-inline: 10px;
}

.venda-perdida-footer .footer-rigth {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.venda-perdida-footer .btn-excluir {
  width: 100px;
  height: 45px;
  display: flex;
  align-items:center ;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  color: #757575;
}

.venda-perdida-footer .btn-excluir:hover {
  background-color:rgb(252, 34, 118);
  color: white;
}


.venda-perdida-footer .btn-cancel {
  background-color: rgb(179, 179, 179);
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.venda-perdida-footer .btn-cancel:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.venda-perdida-footer .btn-save {
  background-color: dodgerblue;
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: white;
  font-weight: 700;
}

.venda-perdida-footer .btn-save:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}








