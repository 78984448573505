.card-container {
    position: relative;

    width: 100%;
    min-width: 290px;

    max-width: 290px;
    display: flex;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 1px 0 rgba(68, 68, 68, 0.25), 0 0 0 1px rgba(97, 97, 97, 0.08), 0 2px 0 rgba(97, 97, 97, 0.16);

    margin-bottom: 8px;
    margin-top: 8px;
    flex-direction: column;

}

.motivo-perda-card-container {
    width: 100%;
    min-height: 20px;
    background-color: #ff425b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 5px;
    margin-top: 5px;
}




.card-container-message {
    box-shadow: 0 0 0 1.5px #ff4e74, rgba(70, 70, 70, 0.418) 0px 4px 10px -2px, rgba(66, 66, 66, 0.473) 0px 3px 7px -3px;
    position: relative;

    width: 100%;
    min-width: 290px;

    max-width: 290px;
    display: flex;
    border-radius: 6px;
    background-color: white;

    margin-bottom: 8px;
    margin-top: 8px;
    flex-direction: column;
}


.card-container-message:hover {
    box-shadow: 0 0 0 2px #4eafff, rgba(70, 70, 70, 0.418) 0px 4px 10px -2px, rgba(66, 66, 66, 0.473) 0px 3px 7px -3px;

}




.card-body-item-arquivado {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
    color: #616275;
}


.motivo-arquivado-card-container {
    width: 100%;
    min-height: 25px;
    background-color: #ff425b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 5px;
    margin-top: 2px;
    font-size: 13px;
}


.card-container:hover {
    box-shadow: 0 0 0 2px #4eafff, rgba(70, 70, 70, 0.418) 0px 4px 10px -2px, rgba(66, 66, 66, 0.473) 0px 3px 7px -3px;

}

.rotulo-container {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    width: 5px;
    height: 100%;
    background-color: rgb(124, 124, 124);
    z-index: 10;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card-header {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #616275;
    font-weight: 600;
    font-size: 13px;
    border-bottom: #f3f3f3 solid 1px;
    padding-left: 15px;
    margin-bottom: 5px;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding-inline: 5px;
    padding-left: 8px;
}

.card-body-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 23px;
    font-size: 13px;
    color: #616275;
}

.card-body-item-column {
    display: flex;
    align-items: center;
    width: 100%;
    height: 23px;
    font-size: 13px;
    color: #616275;
}

.card-body-item-chat-bot {
    display: flex;
    align-items: center;
    height: 23px;
    font-size: 13px;
    color: #616275;
}

.card-body-item-separate-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #616275;
    flex-direction: column;
}

.card-body-item-separate-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
    font-size: 13px;
    color: #616275;
}

.card-body-item-separate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 15px;
    font-size: 12px;
    color: #616275;
}

.card-body-item-fone {
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    height: 23px;
    font-size: 13px;
    color: #616275;
}

.card-body-item-fone-number {
    display: flex;
    align-items: center;
    justify-content:space-between;
    height: 23px;
    font-size: 13px;
    color: #616275;
}

.icons-whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    height: 19px;
    width: 19px;
    color: #ffffff;
    background-color: #26CC63;
    border-radius: 4px;
    padding: 2px;
}

.icons-whatsapp:hover {
    height: 23px;
    width: 23px;
}

.card-valor-item-separate {
    font-weight: 700;
    font-size: 14px;
}

.card-icon-item {
    margin-right: 5px;
    font-size: 18px;
    color: #82839E;
}

.card-valor-item {
    font-weight: 700;
    font-size: 16px;
    margin-left: 0px;
}

.card-footer {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content:space-between ;
    position: relative;
    padding-inline: 5px;
    border-top: #f3f3f3 solid 1px;
    margin-top: 5px;
}



.etapa-producao {
    min-width: 45px;
    background-color: #26CC63;
    border-radius: 5px;
    color: white;
    font-size: 10px;
    min-height: 21px;
    margin-inline: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.card-star-container {
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    margin-left: 0px;
}

.card-icon-star {
    font-size: 18px;
    color: #C4C4C4;
}

.card-icon-star:hover {
    font-size: 25px;
    color: gold;
}

.card-icons-status-container {
    width: 80px;
    max-width: 80px;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 2px;
    font-size: 20px;
    margin-left: 5px;
}

.card-icon-em-andamento {
    color: rgb(138, 138, 138);
}

.card-icon-em-andamento:hover {
    font-size: 26px;
}

.card-icon-vendido {
    color: rgb(87, 190, 250);
    margin-right: 3px;
    
}

.card-icon-vendido:hover {
    font-size: 24px;
}

.card-icon-perdido {
    color:rgb(255, 20, 98);
    margin-right: 3px;
}

.card-icon-perdido:hover {
    font-size: 24px;
}

.label-modulos-title {
    font-size: 11px;
    color: rgb(138, 138, 138);
    margin-left: 5px;
    min-height: 14px;
}

.btns-card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.btn-update-card {
    width: 35px;
    min-height: 35px;
    max-height: 35px;
    border: none;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #a7a7a7;
    color: white;
    margin-inline: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
}

.btn-update-card:hover {
    background-color:dodgerblue;
}

.logo-faktory-module {
    width: 100%;
}

.icons-btns-update-card {
    font-size: 20px;
}

.edit-status-footer-container {
    width: calc(100% - 0px);
    height: 126px;
    background-color: rgba(0, 0, 0, 0.671);
    border-radius: 4px;
    z-index: 1000;
    position:absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.icon-edit-status-card-container {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-inline: 5px;
}

.icon-edit-status-card-container:hover {
    background-color: #ececec;
}






.select-column-card {
    color: #ffffff;
    height: 21px;
    font-size: 13px;
    border-radius: 4px;
    border: none;
    background-color: #C4C4C4;
    padding-inline: 2px;
    max-width: 210;
    min-width: 100px;
    margin-left: 10px;


    

}

.card-body-item button {
    pointer-events: none; /* Desativa a propagação do evento hover nos botões */
}

.card-container:hover .card-body-item button {
    pointer-events: auto; /* Reativa a interação com os botões quando o card está em hover */
}


.select-column-card:focus {
    background-color: rgb(121, 121, 121);
    color: white;
    outline: none;
    border-color: initial;
    box-shadow: none;
}

.card-body-item-select-column {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    height: 23px;
    
}

.icons-shared-card {
    position: absolute;
    right: 6px;
    width: 20px;
    height: 20px;
    color: rgb(172, 172, 172);
    padding: 2px;
    border-radius: 4px;
}

.icons-shared-card:hover {
    color: rgb(255, 255, 255);
    background-color:dodgerblue;
    cursor:default;
}



/* Estilo para o select */
.select-etiqueta-card {
    color: #ffffff;
    height: 21px;
    font-size: 13px;
    border-radius: 4px;
    border: none;
    background-color: #C4C4C4;
    padding-inline: 2px;
    max-width: 210px; /* Corrigido para incluir 'px' */
    min-width: 100px;
    margin-left: 4px;
}

/* Estilo para o select quando está em foco */
.select-etiqueta-card:focus {
    background-color: rgb(121, 121, 121);
    color: white;
    outline: none;
    border-color: initial;
    box-shadow: none;
}


/* Estilo para as opções do select */
.select-etiqueta-card option {
    background-color: #fff; /* Cor de fundo padrão */
    color: #000; /* Cor do texto */
    height: 30px; /* Aumenta a altura dos itens */
    padding: 5px; /* Adiciona padding interno */
    margin-bottom: 2px; /* Adiciona margem inferior */
}

/* Estilo para as opções quando em foco */
.select-etiqueta-card option:hover {
    background-color: #e0e0e0; /* Cor de fundo ao passar o mouse */
}

.item-select-etiqueta-card {
    color: white;
}













.row-column-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 23px;
}


.lock-column {
    min-width: 20px;
    height: 21px;
    padding: 3px;
    background-color: #4eafff;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.lock-column:hover {
    background-color: rgb(97, 97, 97);
}





.btn-delete-card {
    min-width: 20px;
    height: 21px;
    padding: 2px;
    background-color: #ff4d47;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.btn-delete-card:hover {
    background-color: rgb(128, 128, 128);
}

.btn-close-select-status {
    width: 22px;
    height: 22px;
    background-color: #7e7e7e;
    color: white;
    border: none;
    border-radius: 15px;
    position:absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
    background-color: rgb(250, 52, 52);
}


.btn-close-select-status:hover {
    background-color: rgb(247, 97, 97);
}

.label-modulos-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}


.card-n-dias {
    min-width: 21px;
    max-width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c2c2c2;
    color: white;
    border-radius: 4px;
    font-size: 10px;
    margin-right: 3px;
    padding-top: 1px;
    margin-top: 1px;
}

.card-n-dias-expanded {
    min-width: 70px;
    max-width: 70px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c2c2c2;
    color: white;
    border-radius: 4px;
    font-size: 10px;
    margin-right: 3px;
    padding-top: 1px;
    margin-top: 1px;
    position:absolute;
    right: 4px;
}

.btn-create-new-module-container {
    width:  35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #bdbdbd5b;
    margin-left: 2px;
}

.btn-create-new-module {
    min-width: 20px;
    height: 20px;
    background-color: #4eafff;
    color: white;
    border-radius: 20px;
    border: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1px;
    padding-top: 1px;
}

.btn-create-new-module:hover {
    background-color: #f84b35;
}



/* ------------- CRIAR NOVO MÓDULO --------- */

.create-new-module-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    min-height: 100px;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 8px;
    padding: 8px;
}

.create-new-module-input {
    width: 100%;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    height: 45px;
    border-radius: 5px;
    border: solid 1px #A1A2A2;
    outline: none;
    color: #505050;
    text-align: center;
} 


.create-new-module-btns-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btn-create-new-module-cancel {
    padding-inline: 5px;
    height: 30px;
    background-color: #26CC63;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
}

.btn-create-new-module-create {
    padding-inline: 5px;
    height: 30px;
    background-color:#4eafff;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
}

.btn-open-status-container {
    min-width: 32px;
    height: 21px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #4eafff;
    margin-left: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
}

.btn-open-status-container:hover {
    background-color: #f5355f;
}

.link-copied-message {
    color: rgb(255, 255, 255);
    margin-top: 10px;
    font-size: 13px;
    animation: fadeOut 3s forwards;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.671);
    min-height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding-inline: 8px;
    bottom: 45px;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
  }








  /* style.css */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .card-loading {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    height: 100%;

    position: relative;

  }
  
  
  .btn-close-loading-card {
    background: transparent;
    border: none;
    color: white;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
    border: none;
  }


    
  .btn-close-loading-card:hover {
    background-color: rgb(255, 0, 0);
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .loading-message {
    color: white;
    font-size: 13px;
    margin-top: 10px;
  }
  



  .btn-update-card-faktory {
    width: 35px;
    min-height: 35px;
    max-height: 35px;
    border: none;
    border-radius: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #f1f1f1;
    color: white;
    margin-inline: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
}

.btn-update-card-faktory:hover {
    background-color:rgb(207, 207, 207);
    
}
  
.temperatura-container {
    width: calc(100%);
    height: 40px;
    background-color: #ebebeb;
    margin-top: 2px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 3px;
}

.btn-temperatura-frio {
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    background-color: #E41612;
    border-radius: 5px;
    color: white;
    height: 24px;
    font-size: 12px;
}


.btn-temperatura-frio:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}

.btn-temperatura-morno {
    width: 100%;
    border: none;
    background-color: #F9B200;
    margin-inline: 3px;
    border-radius: 5px;
    color: white;
    height: 24px;
    font-size: 12px;
}

.btn-temperatura-morno:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}

.btn-temperatura-quente {
    width: 100%;
    height: 24px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    background-color: #06A700;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.btn-temperatura-quente:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 8px -1px, rgba(255, 10, 10, 0.685) 0px 1px 3px -1px;
}




.icons-task-card-mini {
    background-color: #505050;
    width: 21px;
    min-width: 21px;
    height: 21px;
    padding: 3px;
    color: #ffffff;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 2px;

}


.info-tarefas-conatiner-card {
    width: 230px;
    height: 100px;
    display: flex;
    background-color: #00000091;
    border-radius: 5px;
    position: absolute;
    left: 25px;
    bottom: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.card-n-dias-tarefas {
    min-width: calc(100% - 20px);
    max-width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff116c;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 3px;
    padding-top: 1px;
    margin-top: 1px;
    margin-bottom: 5px;
    margin-top: 5px;
}


