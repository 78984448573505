.list-columns-container {
    margin-top: 30px;

    height: calc(100vh - 120px);
    overflow-y: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 5px;
    border-radius: 8px;
}

.list-columns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-columns-form-container {
    height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
}

.item-list-columns {
    width: 300px;
    overflow: hidden;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.item-list-columns:hover {
    box-shadow: rgba(58, 173, 218, 0.589) 0px 4px 10px -2px, rgba(1, 108, 231, 0.541) 0px 3px 7px -3px;
}

.list-column-actions-container {
    width: 100px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-column-label-name {
    padding-inline: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-columns-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-display-order {
    height: 40px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

 


.btn-edite-column {
    min-height: 30px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: deepskyblue;
    padding-inline: 5px;
    margin-right: 8px;
}

.btn-delete-column {
    min-height: 30px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(255, 30, 0);
    padding-inline: 5px;
    margin-right: 20px;
}