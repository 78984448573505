.modal-messenger {
    position: fixed;
    right: 0;
    top: 0;
    background-color: #0000000e;
    z-index: 1100;
}


/* Desativa a interação do mouse com os botões */
.message-label button {
    pointer-events: none;
}

/* Reativa a interação do mouse com os botões quando o card está em hover */
.message-label:hover button {
    pointer-events: auto;
}


.messenger-container {
    position: fixed;
    top: 38px;
    right: 4px;
    width: 460px;
    max-width: calc(100vw - 10px);
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    display: flex;
    border-radius: 10px;
    background-color: #00aeff;
    box-shadow: rgba(70, 70, 70, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding-inline: 5px;
    z-index: 1002;


    flex-direction: column;
    overflow: hidden;

}

.messenger-username-label {
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #383838;
    font-weight: 700;
    margin-top: 5px;
    margin-left: 10px;
}

.messenger-body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    padding-inline: 8px;
    margin-top: 15px;

}

.messenger-body::-webkit-scrollbar {
    width: 6px;
}

.messenger-body::-webkit-scrollbar-thumb {
    background-color: #e9e9e9;
    border-radius: 5px;
}

.item-list-messenger {
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 8px;
    padding-inline: 6px;
    overflow-y: auto;
    background-color: #ffffff;
    color: darkgrey;
}

.item-list-messenger:hover {
    background-color: #e0e0e0b2;
}

.user-logo-messenger-container {
    min-width: 50px;
    min-height: 50px;
    width: 50px;

    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(70, 70, 70, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}



.messenger-logo-user {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    margin: 0;
    border-radius: 3px;
}

.user-logo-message-container {
    min-width: 40px;
    min-height: 40px;
    width: 40px;

    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 5px;
    background-color: rgb(255, 255, 255);
}

.message-logo-user {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    margin: 0;
    border-radius: 3px;
}

.header-messenger {
    background-color: #00aeff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 900;
    flex-direction: column;
    min-height: 80px;
}

.message-container {
    position: fixed;
    top: 38px;
    right: 4px;
    width: 460px;
    max-width: calc(100vw - 10px);

    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    height: 100%;
    display: flex;
    border-radius: 8px;
    background-color: rgb(0, 174, 255);
    z-index: 1003;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
}

.message-header {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.message-logo-user {
    width: 35px;
    height: 35px;
    object-fit: contain;
    margin: 0;
    border-radius: 5px;
}

.icons-back-message {
    color: white;
    width: 20px;
    height: 20px;
    margin-inline: 10px;
}

.message-body {
    height: 100%;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    padding-inline: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.message-body::-webkit-scrollbar {
    width: 6px;
}

.message-body::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 5px;
}

.message-footer {
    width: 100%;
    padding-inline: 8px;
    display: flex;
    align-items: center;
}

.message-input {
    background-color: rgb(255, 255, 255);
    width: calc(100% - 40px);
    min-height: 40px;
    border-radius: 30px;
    border: none;
    padding-inline: 12px;
    color: rgb(136, 136, 136);
    outline: none;
}

.message-input:focus {
    border: none;
}

.icon-send-message {
    width: 26px;
    height: 26px;
    margin-left: 10px;
}

.icon-send-message:hover {
    color: rgb(70, 70, 70);
}

.item-list-message {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.message-label {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 13px;
    padding-inline: 8px;
    background-color: rgb(78, 78, 78);
    padding: 8px;
    border-radius: 10px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    min-height: 40px;
    box-shadow: rgba(59, 59, 59, 0.25) 0px 4px 10px -2px, rgba(49, 49, 49, 0.24) 0px 3px 7px -3px;

}

.date-message-label {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 9px;
    padding-inline: 8px;
    padding: 2px;
    display: flex;
    align-items: center;
}

.close-messenger-modal {
    width: 23px;
    height: 23px;
    border-radius: 5px;
    border: none;
    background-color: #ffffff;
    color: rgb(105, 105, 105);
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;

}

.close-messenger-modal:hover {
    background-color: rgb(255, 103, 103);
    color: white;

}

/* Ajuste para telas menores que 800px */
@media (max-width: 800px) {

    .messenger-container,
    .message-container {
        width: calc(100vw - 10px);
        right: 5px;
        bottom: 30px;
    }
}

.icone-check-message {
    font-size: 19px;
    margin-left: 5px;
}



.input-search-user-messenger-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}


.input-search-user-messenger {
    width: calc(100% - 20px);
    padding: 8px;
    border: 1px solid #cccccc00;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s;
    min-height: 35px;
}

.input-search-user-messenger:focus {
    border-color: #ff5050;
    outline: none;
}

.column-messender-item-label {
    display: flex;
    flex-direction: column;
}

.column-item-messenger-main {

    display: flex;
    width: 100%;
}

.label-user-type-messenger-container {
    width: max-content;
    background: rgb(119, 119, 119);
    border-radius: 3px;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 5px;
    height: 15px;
}

.label-state-messenger {
    width: max-content;
    background: rgb(119, 119, 119);
    border-radius: 3px;
    padding-inline: 5px;
    margin-left: 5px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.footer-messenger-container {
    min-height: 20px;
    display: flex;
    background-color: #00aeff;

}

.messenger-username-label-destinataria {
    font-size: 14px;
    margin-left: 15px;
    font-weight: 700;
}



/* Container da busca dentro das mensagens */
.search-messages-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    width: 100%;
    background-color: #00aeff;
    margin-top: 10px;
}

.search-input-message {
    width: calc(100% - 100px);
    padding: 8px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
    min-height: 35px;
    margin-right: 5px;
}

.search-input-message:focus {
    border-color: #00aeff;
    outline: none;
}

/* Botão de busca */
.btn-search-message {
    background-color: #ebebeb;
    color: white;
    border: none;
    padding: 5px 5px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-search-message:hover {
    background-color: #007bbd;
}

/* Container dos botões de navegação */
.navigation-buttons {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.btn-prev-message,
.btn-next-message {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #00aeff;
    padding: 6px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s ease;
}

.btn-prev-message:hover,
.btn-next-message:hover {
    background-color: #e0f3ff;
    border-color: #00aeff;
}

/* Indicação visual para mensagens encontradas */
.message-highlight {
    background-color: #fffbcc !important;
    border: 2px solid #ffcc00;
}


.message-options {
    background-color: #ff5050;
    color: white;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    font-size: 22px;
    margin-left: 8px;
}

.message-options:hover {
    background-color: #2b2b2b;
}

.reply-container {
    width: 450px;
    background-color: #2b2b2b62;
    z-index: 50000;
    border-radius: 8px;
    position: fixed;
    bottom: 80px;
    right: 10px;
    padding: 8px;
}

.reply-container-reply {
    background-color: #ffef08ea;
    z-index: 50000;
    border-radius: 8px;
    padding: 10px;
    color: #2b2b2b;
    font-size: 14px;
}

.reply-container-reply:hover {
    background-color: #ffef085e;
}

.close-reply {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    height: 20px;
    background-color: #2b2b2b;
    color: white;
    border-radius: 5px;
}


.close-reply:hover {
    background-color: #ff0909;
}

.reply-message-msg {
    width: 100%;
    padding: 5px;
    background-color: #00aeff;
    border-radius: 5px;
    margin-top: 8px;
}
