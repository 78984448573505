.faktory-modal {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 10001;
}

.header-faktory-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background-color: #ffffff;
    color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    z-index: 1001;
}

.logo-faktory-module-module {
    width: 60px;
}

.btn-close-faktory-module {
    width: 40px;
    height: 40px;
    background-color: rgb(255, 42, 78);
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}

.body-faktory-container {
    display: flex;
    width: calc(100vw);
    height: calc(100vh - 100px);
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-around;
    background-color: #eee;
}

.faktory-container-pedido-venda {
    flex: 1;
    min-width: 250px;
    max-width: 600px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    color: #333;
}

.pedido-venda-faktory-container {
    width: 100%;
    flex: 1;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding: 15px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.pedido-venda-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;
}

.title-faktory-module-container {
    margin-bottom: 8px;
}

.title-faktory-hader {
    color: #ff3c3c;
    font-size: 24px;
}
