.header-container {
    /* background-color: red; */
    background: linear-gradient(90deg, rgb(36, 0, 8) 0%, rgba(168, 86, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);
    width: calc(100vw - 0px);
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
    color: white;
    z-index: 100;
    border-radius: 0px;
}

/*-------- MENU ESQUERDO ----------*/

.modal-left-menu-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000017;
    z-index: 110;
}

.header-menu-left {
    /*background-color: blue;*/
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 18px;
}

.header-menu-left:hover {
    font-size: 20px;
}

.left-menu-container {
    position: fixed;
    left: 5px;
    top: 38px;
    width: 220px;
    height: calc(100vh - 80px);
    background-color: #9555e4;
    border-radius: 10px;
    padding: 10px;
    z-index: 100;
}

.left-menu-title {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-menu-button {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    background-color: aliceblue;
}

.left-menu-button:hover {
    background-color: blueviolet;
    color: white;
}

/*-------- LOGO CENTRAL ----------*/

.header-logo-center {
    /* background-color: yellow; */
    width: 150px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
}

.header-logo-center-logo {
    max-width: 80%;
    /* Ajusta a largura máxima para 100% do container */
    max-height: 80%;
    /* Ajusta a altura máxima para 100% do container */
    object-fit: contain;
    /* Mantém a proporção sem cortar a imagem */
    margin: 0;
    /* Centraliza a imagem no container */
    border-radius: 20px;
    margin-right: 8px;
}

.header-logo-center-label {
    font-weight: 900;
    font-size: 18px;
}

/*-------- MENU DIREITO ----------*/

.header-menu-right {
    width: 130px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    margin-right: 20px;
}

.icons-number-notifications-header {
    width: 11px;
    height: 11px;
    color: rgb(255, 0, 0);
    position: fixed;
    top: 4px;
    right: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    border: none;
    border-radius: 50%;
    z-index: 100;
}


.number-notifications-header {
    min-height: 16px;
    height: 16px;
    background-color: rgb(255, 0, 0);
    color: white;
    position: fixed;
    top: 4px;
    right: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    border: none;
    border-radius: 3px;
    z-index: 100;
    padding-inline: 2px;
}

.total-mensagens-text {
    width: 100%;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.icon-notification-header {
    color: rgb(255, 255, 255);
    font-size: 20px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
}

.icon-notification-header:hover {

    background-color: rgb(145, 145, 145);

}

.icon-card-potencial-header {
    color: rgb(255, 255, 255);
    font-size: 20px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
}

.icon-card-potencial-header:hover {

    background-color: rgb(145, 145, 145);

}


.img-card-potencial-header {
    color: rgb(255, 255, 255);
    font-size: 20px;
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 2px;
}

.img-card-potencial-header:hover {

    background-color: rgb(145, 145, 145);

}

.header-user-logo-container {
    position: fixed;
    top: 5px;
    right: 5px;
    background-color: rgb(255, 255, 255);
    width: 55px;
    height: 55px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 500;
    transition: width 0.3s ease, height 0.3s ease, box-shadow 0.3s ease;
}

.header-user-logo-container:hover {
    box-shadow: rgb(25, 143, 253) 0px 4px 10px -2px, rgba(0, 0, 0, 0) 0px 3px 7px -3px;
    width: 60px;
    height: 60px;
}


.header-users-logo-header {
    max-width: 100%;
    /* Ajusta a largura máxima para 100% do container */
    max-height: 100%;
    /* Ajusta a altura máxima para 100% do container */
    object-fit: contain;
    /* Mantém a proporção sem cortar a imagem */
    margin: 0;
    /* Centraliza a imagem no container */
    overflow: hidden;
    border-radius: 10px;

}



.header-users-logo {
    max-width: 100%;
    /* Ajusta a largura máxima para 100% do container */
    max-height: 100%;
    /* Ajusta a altura máxima para 100% do container */
    object-fit: contain;
    /* Mantém a proporção sem cortar a imagem */
    margin: 0;
    /* Centraliza a imagem no container */
    overflow: hidden;
    border-radius: 10px;

}

.header-users-logo:hover {

    background-color: #e4e4e4;
    padding: 6px;
}




.user-infors-container {
    width: 370px;
    height: 500px;
    border-radius: 10px;
    background-color: white;
    position: fixed;
    right: 5px;
    top: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 1000;
}

.user-infors-logo-container {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5px;
    
}

.user-infors-logo {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    margin: 0;
    border-radius: 5px;
}

.user-infors-logo:hover {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    margin: 0;
    border-radius: 5px;

}

.user-infors-body-container {
    width: 100%;
    height:220px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    flex-direction: column;
}

.user-infors-footer-container {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-close {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: dodgerblue;
    position: absolute;
    bottom: 10px;
    right: 6px;
}

.user-info-name {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #585858;
    font-weight: 800; 
}

/* ProgressBar.css */

.title-progress-bar {
    font-size: 14px;
    color: #585858;
    font-weight: 600; 
    width: 100%;
    margin-left: 30px;
}

.value-meta-label {
    font-size: 14px;
    margin-bottom: 30px;
    color: #585858;
    font-weight: 600;
}

.title-meta {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 20px;
}

.progress-bar-container {
    width: calc(100% - 15px);
    min-height: 30px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.progress-bar {
    min-height: 26px;
    background: linear-gradient(50deg, rgba(2, 0, 36, 1) 0%, rgb(255, 86, 170) 0%, rgba(0, 212, 255, 1) 70%, rgb(43, 255, 0) 100%);
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 5px;
    transition: width 3.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-label {
    width: 60px;
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: rgb(12, 225, 253);
    position:absolute;
    left: calc(50% - 30px);
    background-color: rgba(0, 0, 0, 0.267);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.menu-notification-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: calc(100vh);
    background-color: #F0F2F5;
    display: flex;
    z-index: 1000;
    flex-direction: column;
    background-color: #0b99f894;
    backdrop-filter: blur(7px);
    padding-inline: 5px;
}

.menu-notification-body {
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    
}

.menu-notification-body::-webkit-scrollbar {
    width: 7px;
}

.menu-notification-body::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.menu-notification-header {
    min-height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    
}

.btn-close-notifications-menu {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 5px;
    background-color: #a5a5a5;
    margin-left: 0px;
    color: white;
}

.btn-close-notifications-menu:hover {
    background-color:deeppink;
}




.btn-icon-calendario {
    min-width: 25px;
    min-height: 25px;
    border: none;
    border-radius: 5px;
    background-color: #505050;
    margin-left: 0px;
    color: white;
    margin-left: 8px;
    padding: 2px;
}

.btn-icon-calendario:hover {
    background-color:deeppink;
}


.item-notifications {
    width: calc(100% - 0px);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    padding-inline: 10px;
}

.label-notifications-header {
    color: #858585;
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    padding: 5px;
    overflow: hidden;
}

.icon-notifications-card {
    min-width: 17px;
    min-height: 17px;
    margin-right: 8px;
}



.icon-messenger-flutuante {
    position: fixed;
    right: 15px;
    bottom: 40px;
    width: 42px;
    height: 42px;
    z-index: 1000;
    background-color: #ff0077;
    border-radius: 50px;
    padding: 7px;
    color: white;
}

.icon-messenger-flutuante:hover {
    background-color:#9555e4;
    width: 46px;
    height: 46px;
    right: 13px;
    bottom: 38px;
}

.badge {
    position: fixed;
    right: 10px;
    bottom: 65px;
    z-index: 1001;
    min-width: 20px;
    border-radius: 4px;
    background-color: rgb(253, 40, 40);
    color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding: 4px;
}

.mensagens-nao-lidas{
    z-index: 1001;
    min-width: 20px;
    border-radius: 4px;
    background-color: rgb(253, 40, 40);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: 10px;
    padding: 4px;
}

.btn-edit-avatar-user {
    min-height: 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 15px;
    position: absolute;
    top: 80px;
    padding-inline: 5px;
    font-size: 10px;
}

.btn-close-metas-user {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(97, 97, 97);
    color: white;
    border: none;
    border-radius: 15px;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-inline: 5px;
    font-size: 12px;
    padding-bottom: 1px;
}

.btn-close-metas-user:hover {

    background-color: red;

}







.date-input-header {
    margin-left: 10px;
  }
  
  .tasks-for-date-container {
    margin-top: 20px;
  }
  
  .task-item {
    margin-bottom: 10px;
  }
  


  .img-menu-header-user {
    position: absolute;
    bottom: 10px;
    left: 120px;
    z-index: 1;
    width: 150px;

    height: auto; /* Manter a altura automática para preservar a proporção */

}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .icon-messenger-flutuante.pulsing {
    animation: pulse 1.0s infinite;
  }
  










  .cards-potenciais-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: #00000021;
    
  }

  .container-analise-potencial-vendas-container {
    width: 80%;
    max-width: 600px;
    min-height: 300px;
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    padding-inline: 18px;
    max-height: calc(100vh - 60px); /* Ajuste a altura máxima conforme necessário */
    overflow-y: auto; /* Garantir que o contêiner tenha rolagem se necessário */
    padding-top: 10px;
    z-index: 1000;
    position: fixed;
    top: 45px;
}

.btn-close-modal-cards-potenciais {
    width: 30px;
    height: 30px;
    background-color: rgb(68, 68, 68);
    color: white;
    border-radius: 15px;
    border: none;
    position: absolute;
    right: 20px;
    top: 10px;
}

.btn-close-modal-cards-potenciais:hover {
    background-color: rgb(252, 19, 97);

}



.card-potencial-venda {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding-inline: 15px;
    margin-bottom: 15px;
    padding-top: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    
}

.card-potencial-venda:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-potencial-venda h3 {
    color: #2c3e50;
    font-size: 14px;
    margin-bottom: 5px;
}

.card-potencial-venda p {
    color: #7f8c8d;
    font-size: 12px;
    margin: 5px 0;
}


.container-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.title-icon {
    font-size: 28px;
    color: #2bbbc0;
    margin-right: 10px;
}

.container-title h3 {
    color: #2c3e50;
    font-size: 20px;
}

.list-cards-potenciais {
    overflow-y: auto;
}


.card-header-card-potencial {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .gold-medal {
    color: #FFD700; /* Cor dourada */
    font-size: 24px;
  }
  
  .silver-medal {
    color: #C0C0C0; /* Cor prateada */
    font-size: 24px;
  }
  
  .bronze-medal {
    color: #CD7F32; /* Cor de bronze */
    font-size: 24px;
  }
  
  .score-text {
    font-size: 14px;
    color: #2c3e50;
  }




  .motivational-quote {
    width: 600px;
    font-size: 13px;
    position: fixed;
    left: 60px;
    top: 10px;
    color: #ffffff;
    font-weight: 700;
  }

  .hamburger {
    width: 30px;
  }
  



