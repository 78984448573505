/* Container principal */
.participantes-page-container {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden; /* Evitar que o conteúdo saia da tela horizontalmente */
}

/* Lista de participantes */
.participantes-list-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-bottom: 20px;
    max-width: 100%; /* Garante que a lista não ultrapasse a largura da tela */
    box-sizing: border-box; /* Inclui padding e border no cálculo da largura */
}

.participantes-list-container h2 {
    color: #616161;
    margin-bottom: 20px;
}

.participantes-list-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.participantes-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%; /* Garante que os itens da lista não ultrapassem a largura da tela */
    box-sizing: border-box;
    word-wrap: break-word; /* Quebra palavras longas */
}

.participantes-list-item:hover {
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.participantes-list-item span {
    font-weight: bold;
    color: #555;
}

.participantes-edit-button {
    background-color: #984caf;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.participantes-edit-button:hover {
    background-color: #45a049;
}

/* Botões gerais */
.participantes-create-button, 
.participantes-submit-button, 
.participantes-cancel-button, 
.participantes-delete-button {
    background-color: #984caf;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
    max-width: calc(100% - 20px); /* Garante que os botões não ultrapassem a largura da tela */
}

.participantes-cancel-button {
    background-color: #757575;
}

.participantes-delete-button {
    background-color: #d9534f;
}

.participantes-create-button:hover, 
.participantes-submit-button:hover, 
.participantes-cancel-button:hover, 
.participantes-delete-button:hover {
    background-color: #984caf;
}

.participantes-cancel-button:hover {
    background-color: #5e5e5e;
}

.participantes-delete-button:hover {
    background-color: #c9302c;
}

/* Modal Overlay */
.participantes-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Conteúdo do Modal */
.participantes-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    max-height: calc(100vh - 40px); /* Garante que o modal não ultrapasse a altura da tela */
    overflow-y: auto;
    box-sizing: border-box; /* Garante que o padding não faça a div ultrapassar a largura da tela */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* Formulário */
.participantes-form {
    display: flex;
    flex-direction: column;
    max-width: 100%; /* Garante que o formulário não ultrapasse a largura da tela */
    box-sizing: border-box;
}

.participantes-input, 
.participantes-select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.participantes-input:focus, 
.participantes-select:focus {
    border-color: #4CAF50;
}

/* Mensagem de erro */
.participantes-error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

.participantes-form-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
