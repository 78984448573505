.pdf-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.pdf-viewer-content {
  position: relative;
  width: 98%;
  height: 98%;
  background: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pdf-viewer-close {
  min-width: 30px;
  min-height: 30px;
  background: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100000000;
  background-color: red;
  color: white;
  border-radius: 50%;
}

.pdf-viewer-close:hover {
  background-color: chartreuse;
}

.pdf-viewer-scroll {
  flex-grow: 1;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

canvas {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 98% !important; /* Garante que o canvas ocupe 98% da largura */
  height: auto; /* Mantém a proporção do PDF */
}

.search-overlay {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.pdf-search-input {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
