.google-calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.google-calendar-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.event-list {
  margin-top: 20px;
}

.event-list ul {
  list-style-type: none;
  padding: 0;
}

.event-list li {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.event-list li strong {
  color: #007bff;
}

.event-date {
  background: #ffeb3b; /* Cor de destaque para as datas com eventos */
  color: black;
  border-radius: 50%;
}

