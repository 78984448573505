.modal-backdrop {
  background: rgba(0, 0, 0, 0.945);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
}

.main-tools-project-container {
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  padding-inline: 20px;
}

.icon-main-tools-project {
  width: 40px;
  height: 40px;
  color: rgb(255, 255, 255);
  margin-right: 8px;
}

.icon-main-tools-project:hover {
  background-color: rgba(255, 255, 255, 0.527);
  color: rgb(255, 0, 98);
  border-radius: 5px;
}

.modal-container {
  background: rgba(247, 17, 17, 0);
  width: 100vw;
  height: calc((100vh ) - 60px );
  display: flex;
  justify-content: space-around;
  position: fixed;
  top: 60px;
}

.view-project-container {
  width: calc((60vw ) - 20px );
  height: calc((100vh ) - 120px );
  background-color: rgba(109, 109, 109, 0.473);
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px; /* Adicionando padding para criar espaçamento interno */

}

.tools-project-container {
  width: calc((40vw ) - 10px );
  height: calc((100vh ) - 120px );
  background-color: rgba(126, 126, 126, 0.473);
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
}



.tools-project-container label {
  font-size: 1.1em;
  color: #fff;
}

.tools-project-container input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1em;
  margin-bottom: 10px;
}

.tools-project-container input:focus {
  outline: none;
  border: 2px solid #ff0062;
}