.portal-page-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

.body-portal-container {
    position: fixed;
    top: 36px;
    width: 100%;
    height: calc(100vh - 36px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fefefe;
    overflow-y: auto;
    padding: 20px;
    flex-direction: column;
}

.list-conteudo-principal {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    height: auto;
}

.item-list-conteudo-principal {
    width: 160px;
    height: 160px;
    border-radius: 10px;
    background-color: #fd1f44;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.item-list-conteudo-principal:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

.item-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    object-fit: contain;
    border-radius: 5px;
}

.content-description h4 {
    font-size: 16px;
    text-align: center;
    color: #ffffff;
}



.list-topicos {
    margin-top: 20px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.1);
  }

 .list-midia {
    margin-top: 20px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .item-list-topico {
    padding: 8px;
    margin: 5px 0;
    background-color: #3bb7ff;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .item-list-midia {
    padding: 8px;
    margin: 5px 0;
    background-color: #ececec;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  

  
  .item-list-topico:hover, .item-list-midia:hover {
    background-color: #ff085a;
    transform: translateY(-5px);
    color: white;
  }
  

  .list-midia-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .item-list-midia {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 280px;
  }
  
  .video-thumbnail {
    width: 98%;
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .midia-description {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  

  .modal-portal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.678);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-portal {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 1600px;
    text-align: center;
    position: relative;
    height: 90%;
}

.modal-content-portal iframe {
    width: 100%;
    height: 98%;
    border-radius: 10px;
}

.close-button-portal {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fd1f44;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
}

.close-button-portal:hover {
    background-color: #e3173b;
}
