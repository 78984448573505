.modal-import-container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1101;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.import-container {
  background-color: white;
  width: 600px;
  max-width: 97%;
  max-height: calc(100vh - 125px);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 60px;
}

.import-header {
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  display: flex;
  align-items: center;
  padding-inline: 10px;
}

.import-header .title {
  font-size: 16px;
}

.infos-import-excel {
  font-size: 14px;
  margin-top: 10px;
  color: rgb(255, 85, 85);
}

.import-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.tabela-padrao {
  margin-top: 15px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8px;
}


.title-table {
  min-height: 0px;
  display: flex;
  align-items: center;
  margin-inline: 10px;
}

.tabela {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.column-table {
  width:max-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 1px;
  margin-top: 5px;
  padding-inline: 5px;
  border: 1px solid black;
}



.btn-close-import {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 8px;
}

.btn-close-import:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}


.btn-send-import {
  background-color: dodgerblue;
  color: white;
  width: 70px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 8px;
}

.btn-send-import:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.input-import-file {
  background-color: rgb(99, 99, 99);
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 8px;
  cursor: pointer;
}


.import-footer {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-inline: 10px;
}

.planilha-excel-exemplo {
  color: cornflowerblue;
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 5px;
}

.planilha-excel-exemplo:hover {
  color:deeppink;
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 5px;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
