.projetos-page-container {
    width: 100vw;
    height: 100vh;
  }
  
  .projetos-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-inline: 10px;
  }
  
  .btn-novo-projeto {
    background-color: #424242;
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 5px;

  }
  
  .projetos-list {
    margin-top: 20px;
    display: flex;
  }
  
  .tipologia-card {
    width: 200px;
    height: 240px;
    background-color: #00000080;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
  }

  .tipologia-card:hover {
    background-color: #ff2b60;
  }

  .croqui-tipologia-container {
    width: 190px;
    height: 150px;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
  }

  .descricao-tipologia {
    width: 190px;
    height: 75px;
    margin-top: 5px;
    font-size: 14px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  